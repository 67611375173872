.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.great-vibes-regular {
  font-family: "Great Vibes", cursive;
  font-weight: 400;
  font-style: normal;
}


@mixin ttffont($font-class-name, $font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  #{$font-class-name} {
    font-family: $font-family, cursive;
    font-weight: 400;
    font-style: normal;
  }
}

@include ttffont('.bebas-neue-regular', 'Bebas Neue Regular', './assets/fonts/BebasNeue/BebasNeue-Regular.ttf');
@include ttffont('.great-vibes-regular', 'Great Vibes Regular', './assets/fonts/GreatVibes/GreatVibes-Regular.ttf');
@include ttffont('.dancing-script-regular', 'Dancing Script Regular', './assets/fonts/DancingScript/DancingScript-Regular.ttf');
@include ttffont('.indie-flower-regular', 'Indie Flower Regular', './assets/fonts/IndieFlower/IndieFlower-Regular.ttf');
@include ttffont('.playwrite-dk-loopet-regular', 'Playwrite DK Loopet Regular', './assets/fonts/PlaywriteDKLoopet/PlaywriteDKLoopet-Regular.ttf');
@include ttffont('.roboto-regular', 'Roboto Regular', './assets/fonts/Roboto/Roboto-Regular.ttf');
@include ttffont('.sacramento-regular', 'Sacramento Regular', './assets/fonts/Sacramento/Sacramento-Regular.ttf');
@include ttffont('.zeyada-regular', 'Zeyada Regular', './assets/fonts/Zeyada/Zeyada-Regular.ttf');

//
//@font-face {
//  font-family: 'Bebas Neue Regular';
//  src: url(./assets/fonts/BebasNeue/BebasNeue-Regular.ttf) format('truetype');
//  font-weight: normal;
//  font-style: normal;
//}
//
//.bebas-neue-regular {
//  font-family: "Bebas Neue Regular", cursive;
//  font-weight: 400;
//  font-style: normal;
//}

